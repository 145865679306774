import axios from '../utils/axios';
import * as API_END_POINT from '../constants/api';
import { setLoggedIn } from '../redux/auth/authSlice';
import { setUserInfo } from '../redux/user/userSlice';
import {
  setProductCatOptions,
  setBrandProductList,
  setProductTagOptions,
  setProductDetails,
  setProductSubCatOptions,
  setProductGroupOptions,
  setSyncError,
  setSyncInProgress,
  setSyncSuccess,
  setProductCatPerRetailerOptions,
  setProductTagPerRetailerOptions,
  setProductSubCatAndGroupOptions,
  setUploadFileStatus,
  setUploadFileStatusCount,clearUpdateStatus, setUploadFileStatusError, setProductRetailerListFilter
} from '../redux/Brand/Products/productSlice';
import { toast } from 'react-toastify';
import download from 'js-file-download';
import { showBottomNotification } from './generalActions';
import { setCategories } from '../redux/General/Category/getCategorySlice';
import { setStates } from '../redux/General/States/getStatesSlice';

export function getProductListAction(data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(API_END_POINT.PRODUCT_LIST, data);
      if (response && response.data && response.data.code == 200) {
        dispatch(setBrandProductList(response.data?.data));
      } else {
        toast.error('Something went wrong', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getProductTagsAction() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_END_POINT.PRODUCT_TAGS}`);
      if (response && response.data && response.data.code == 200) {
        if (response.data.data) {
          dispatch(setProductTagOptions(response.data.data));
        }
      } else {
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
  };
}

export function getProductCategoriesAction(type, id, isFilter = "?purpose=filter") {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.PRODUCT_CATEGORIES(id)}${isFilter}`
      );
      if (response && response.data && response.data.code == 200) {
        if (response.data.data) {
          if (type === 'category') {
            dispatch(setProductCatOptions(response.data.data));
          } else if (type === 'subcategory') {
            dispatch(setProductSubCatOptions(response.data.data));
          } else if (type === 'group') {
            dispatch(setProductGroupOptions(response.data.data));
          }
        }
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
  };
}

export function getProductSubCategoriesAndGroupAction(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.PRODUCT_CATEGORIES(id)}/sub-category`
      );
      if (response && response.data && response.data.code == 200) {
        if (response.data.data) {
          dispatch(setProductSubCatAndGroupOptions(response.data.data));
        }
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
  };
}

export function syncSingleProductAction(params, product_name) {
  return async (dispatch) => {
    try {
      // dispatch(showBottomNotification(`Syncying ${product_name}`));
      dispatch(setSyncInProgress());
      const response = await axios.get(`${API_END_POINT.SYNC_SINGLE_PRODUCT}`, {
        params,
      });
      if (response && response.data && response.data.code == 200) {
        // dispatch(setSyncError(response?.data?.data));
        dispatch(setSyncSuccess());
        toast.success("Product sync successful!")
        // dispatch(showBottomNotification('Product sync was successful'));
      } else {
      }
    } catch (err) {
      dispatch(setSyncError(err));
      toast.error(
        'Product sync unsuccessful.', {
          autoClose: false,
        }
      );
      // toast.error(
      //   err && err.response && err.response.data && err.response.data.errors
      //     ? err.response.data.errors
      //     : 'Something went wrong'
      // );
      throw err;
    }
  };
}
export async function getBrandProductTagsAction(body) {
    try {
      const response = await axios.post(
        `${API_END_POINT.BRAND_PRODUCT_TAGS}`,body);
      if (response && response.status == 200) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
}
export async function addBrandProductTagAction(body) {
    try {
      const response = await axios.post(
        `${API_END_POINT.BRAND_ADD_PRODUCT_TAG}`,body);
      if (response && response.status == 200) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
}
export function downloadProductAction(data, brandName) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_END_POINT.DOWNLOAD_PRODUCT}`,
        data
        // {
        //   responseType: 'blob',
        // }
      );
      if (response && response.status == 200) {
        // download(response.data, `${brandName}_products.xlsx`);
        toast.success(response?.data?.data?.message);
      } else {
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
  };
}

export function uploadProductAction(file) {
  return async (dispatch) => {
    try {
      dispatch(clearUpdateStatus())
      let formData = new FormData();

      formData.append('file', file);
      const response = await axios.post(
        `${API_END_POINT.UPLOAD_PRODUCT}`,
        formData
      );
      if (response && response.data && response.data.code == 200) {
        toast.success("Products are being uploaded. We will notify you once the upload is completed!");
        dispatch(setUploadFileStatus());
        // dispatch(showBottomNotification('File uploaded successfully'));
      } else {
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getProductDetailsAction(productId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.PRODUCT_DETAILS(productId)}`
      );
      if (response && response.data && response.data.code == 200) {
        if (response.data.data) {
          dispatch(setProductDetails(response.data.data));
        }
      } else {
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export async function getSingleProductDetailsAction(productId) {
  try {
    const response = await axios.get(
      `${API_END_POINT.PRODUCT_DETAILS(productId)}`
    );
    if (response && response.data && response.data.code == 200) {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    } else {
    }
    return response;
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
  }
}

// export function editProductDetailsAction(data, productId) {
export async function updateProductStatusAction(productId, status) {
  const data = {
    id: productId,
    status: status,
  };
  try {
    const response = await axios.put(
      `${API_END_POINT.UPDATE_PRODUCT_STATUS}`,
      data
    );
    if (response && response.data && response.data.code == 200) {
      return {
        isSuccess: true,
        response: response.data,
      };
    } else {
    }
    return response;
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
  }
}

export async function updateProductTagAction(formData) {
  try {
    const response = await axios.put(
      `${API_END_POINT.UPDATE_BRAND_TAGS}`,
      formData
    );
    if (response && response.data && response.data.code == 200) {
      return {
        isSuccess: true,
        response: response.data,
      };
    } else {
    }
    return response;
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
  }
}
export async function updateProductCategoryAction(formData) {
  try {
    const response = await axios.put(
      `${API_END_POINT.UPDATE_BRAND_CATEGORIES}`,
      formData
    );
    if (response && response.data && response.data.code == 200) {
      return {
        isSuccess: true,
        response: response.data,
      };
    } else {
    }
    return response;
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
  }
}
export async function updateProductVarientAction(formData) {
  try {
    const response = await axios.put(
      `${API_END_POINT.UPDATE_BRAND_VARIENT}`,
      formData
    );
    if (response && response.data && response.data.code == 200) {
      return {
        isSuccess: true,
        response: response.data,
      };
    } else {
    }
    return response;
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
  }
}

export async function editProductDetailsAction(data, productId) {
  try {
    const response = await axios.patch(
      `${API_END_POINT.EDIT_PRODUCT_DETAILS(productId)}`,
      data
    );
    if (response && response.data && response.data.code == 200) {
      toast.success("Your changes have been saved.")
      return {
        isSuccess: true,
      };
    } else {
    }
    return response;
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
    throw err;
  }
}
export async function updateBulkProductAction(data) {
  try {
    const response = await axios.put(
      `${API_END_POINT.PRODUCT_BULK_UPDATE}`,
      data
    );
    console.log(response);
    if (response && response.data && response.data.code == 200) {
      return {
        isSuccess: true,
        response: response.data,
      };
    }
    return response;
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
  }
}
export function getConnectedRetailerCategories() {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_END_POINT.CONNECTED_RETAILER_CATEGORIES}`
      );
      if (response && response?.data && response?.data?.code == 200) {
        if (response?.data?.data) {
          dispatch(setCategories(response?.data?.data?.rows));
        }
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}
export function getConnectedRetailerStates() {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_END_POINT.CONNECTED_RETAILER_STATES}`
      );
      if (response && response?.data && response?.data?.code == 200) {
        if (response?.data?.data) {
          dispatch(setStates(response?.data?.data?.rows));
        }
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}
export function getProductCategoriesPerRetailersAction() {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.PRODUCT_CATEGORIES_PER_RETAILER}`
      );
      if (response && response.data && response.data.code == 200) {
        if (response.data.data) {
          dispatch(setProductCatPerRetailerOptions(response.data.data));
        }
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
  };
}
export function getProductTagsPerRetailersAction() {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.PRODUCT_TAGS_PER_RETAILER}`
      );
      if (response && response.data && response.data.code == 200) {
        if (response.data.data) {
          dispatch(setProductTagPerRetailerOptions(response.data.data));
        }
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
  };
}

export function getProductUploadStatus() {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_END_POINT.PRODUCT_UPLOAD_STATUS}`);
      if (response && response?.data && response?.data?.code == 200) {
        if(response?.data?.data?.response){
          if (response?.data?.data?.response?.rejectCount > 0) {
            dispatch(setUploadFileStatusError(response?.data?.data?.response))
          }
          dispatch(clearUpdateStatus());
        }
      }
    } catch (err) {
      const errorList = err?.response?.data?.data?.response
      dispatch(setUploadFileStatusError(errorList))
      // toast.error(
      //   err && err.response && err.response.data && err.response.data.errors
      //     ? err.response.data.errors
      //     : 'Something went wrong'
      // );
    }
  };
}
export function getBrandConnectedRetailers() {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_END_POINT.CONECTED_RETAILER}`,
        {
          "sort": [],
          "filter": []
        }
      );
      if (response && response.data && response.data.code == 200) {
        if (response.data.data) {
          dispatch(setProductRetailerListFilter(response.data.data.rows));
        }
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
  };
}


// export function updateProductPublishtAction(data, productId) {
  export async function updateProductPublishtAction(data) {
    try {
      const response = await axios.post(`${API_END_POINT.RETAILER_PRODUCT_PUBLISH_ACTION}`, data);
      // console.log('response--->>>>', response);
      if (response && response.data && response.data.code == 200) {
        toast.success(response.data.data);
        return {
          isSuccess: true,
        };
      } else {
        toast.warning("Unexpected response. Please check your data and try again.");
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }
  }

  // export function updatePublishToStoreAction(data, productId) {
    export async function updatePublishToStoreAction(data) {
      console.log('data', data);
      try {
        const response = await axios.post(`${API_END_POINT.RETAILER_PUBLISH_TO_ASSIGN_PRODUCT_ACTION}`, data);
        // console.log('response--->>>>', response);
        if (response && response.data && response.data.code == 200) {
          toast.success(response.data.data);
          return {
            isSuccess: true,
          };
        } else {
          toast.warning("Unexpected response. Please check your data and try again.");
        }
        return response;
      } catch (err) {
        toast.error(
          err && err.response && err.response.data && err.response.data.errors
            ? err.response.data.errors
            : 'Something went wrong', {
              autoClose: false,
            }
        );
        throw err;
      }
    }
    // export function updateUnPublishFromStoreAction(data, productId) {
  export async function updateUnPublishFromStoreAction(data) {

    try {
      const response = await axios.post(`${API_END_POINT.RETAILER_PUBLISH_TO_UNASSIGN_PRODUCT_ACTION}`, data);
      // console.log('response--->>>>', response);
      if (response && response.data && response.data.code == 200) {
        toast.success(response.data.data);
        return {
          isSuccess: true,
        };
      } else {
        toast.warning("Unexpected response. Please check your data and try again.");
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }

  }


  export async function updateStoreProductCategoryAction(data) {
    try {
      const response = await axios.post(`${API_END_POINT.RETAILER_PRODUCT_STORE_CATEGORY_UPDATE_ACTION}`, data);
      if (response && response.data && response.data.code == 200) {
        toast.success("Your changes have been saved.");
        return {
          isSuccess: true,
        };
      } else {
        toast.warning("Unexpected response. Please check your data and try again.");
      }
      return response;
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      throw err;
    }

  }

  export function syncWoocommerceSingleProductAction(params, product_name) {
    return async (dispatch) => {
      try {
        // dispatch(showBottomNotification(`Syncying ${product_name}`));
        dispatch(setSyncInProgress());
        const response = await axios.get(`${API_END_POINT.SYNC_WOOCOMMERCE_SINGLE_PRODUCT}`, {
          params,
        });
        if (response && response.data && response.data.code == 200) {
          // dispatch(setSyncError(response?.data?.data));
          dispatch(setSyncSuccess());
          toast.success("Product sync successful!")
          // dispatch(showBottomNotification('Product sync was successful'));
        } else {
        }
      } catch (err) {
        dispatch(setSyncError(err));
        toast.error(
          'Product sync unsuccessful.', {
            autoClose: false,
          }
        );
        // toast.error(
        //   err && err.response && err.response.data && err.response.data.errors
        //     ? err.response.data.errors
        //     : 'Something went wrong'
        // );
        throw err;
      }
    };
  }

  export function syncMagentoSingleProductAction(params, product_name) {
    return async (dispatch) => {
      try {
        // dispatch(showBottomNotification(`Syncying ${product_name}`));
        dispatch(setSyncInProgress());
        const response = await axios.get(`${API_END_POINT.SYNC_MAGENTO_SINGLE_PRODUCT}`, {
          params,
        });
        if (response && response.data && response.data.code == 200) {
          // dispatch(setSyncError(response?.data?.data));
          dispatch(setSyncSuccess());
          toast.success("Product sync successful!")
          // dispatch(showBottomNotification('Product sync was successful'));
        } else {
        }
      } catch (err) {
        dispatch(setSyncError(err));
        toast.error(
          'Product sync unsuccessful.', {
            autoClose: false,
          }
        );
        // toast.error(
        //   err && err.response && err.response.data && err.response.data.errors
        //     ? err.response.data.errors
        //     : 'Something went worng'
        // );
        throw err;
      }
    };
  }