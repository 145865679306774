export const termsAndCondition =
    'https://shopdotapp.com/shopdot-business-terms-of-service/';
export const privacyPolicy = 'https://shopdotapp.com/privacy-policy/';

export const ALLOWED_PLATFORMS = ['shopify','woocommerce', "magento"];

export const BUSINESS_TYPE_LIST = [
    {
      label: 'Brick-and-mortar Retailer',
      value: 'brick-and-mortar-retailer',
    },
    {
      label: 'Service Business (i.e. pet groomer, spa, medical office)',
      value: 'service-business',
    },
    {
      label: 'Online Seller Only',
      value: 'online-seller-only',
    },
    {
      label: 'Content Creator/Influencer',
      value: 'content-creator-influencer',
    },
    {
      label: 'Non Profit',
      value: 'non-profit',
    },
  ];
