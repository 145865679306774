import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderFilter: { retailerFilter: [], fulfillmentFiter: [], dateRange: {}, chennels: [] },
  fulFillmentOptions: ['fulfilled', 'unfulfilled', 'canceled'],
  orderList: [],
  orderCount: 0,
  orderLoading: false,
  limit: 100,
  offset: 0,
};

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    setOrderFilter: (state, action) => {
      state.orderFilter = action.payload;
    },
    resetToInitial: (state) => {
      state.orderFilter = {
        retailerFilter: [],
        fulfillmentFiter: [],
        dateRange: {},
      };
    },
    retailerClear: (state) => {
      state.orderFilter.retailerFilter = [];
    },
    fulFillmentClear: (state) => {
      state.orderFilter.fulfillmentFiter = [];
    },
    dateRangeClear: (state) => {
      state.orderFilter.dateRange = {};
    },
    setOrderList: (state, action) => {
      state.orderList = action.payload.rows;
      state.orderCount = action.payload.count;
    },
    setOrderLoading: (state, action) => {
      state.orderLoading = action.payload;
    },
    clearOrderLogout: (state) => {
      state = initialState;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setOffset: (state, action) => {
      state.offset = action.payload;
    }
  },
});

/**
 * Actions
 */
export const {
  setOrderFilter,
  resetToInitial,
  retailerClear,
  fulFillmentClear,
  dateRangeClear,
  setOrderList,
  setOrderLoading,
  clearOrderLogout,
  setLimit,
  setOffset,
} = orderSlice.actions;

/**
 * Reducers
 */
export const orderReducer = orderSlice.reducer;
