// const BASE_URL = `https://dev.backend.shopdotapp.com/api/v1`;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const BASE_URL = `${ENDPOINT}/api/v1`;
// const BASE_URL = `https://qabackend.shopdotapp.com/api/v1`;
// const BASE_URL = `http://127.0.0.1:3002/api/v1`;

// Auth
export const REGISTER = `${BASE_URL}/auth/signup/`;
export const VERIFICATION_EMAIL = `${BASE_URL}/auth/verification/send-mail`;
export const VERIFY_USER = `${BASE_URL}/auth/verify`;
export const SIGN_IN = `${BASE_URL}/auth/signin`;
export const SIGN_IN_USER_ROLE = `${BASE_URL}/auth/user-roles`;
export const REFRESH_TOKEN = `${BASE_URL}/auth/refresh-token`;
export const LOGOUT = `${BASE_URL}/auth/signout`;
export const UPDATE_USER_INFO = `${BASE_URL}/user/update-profile`;
export const CHANGE_PASSWORD = (id) =>
  `${BASE_URL}/auth/user/${id}/change-password`;
export const RESET_PASSWORD = (id) =>
  `${BASE_URL}/auth/user/${id}/reset-password`;
export const FORGOTEMAILVARIFICATION = `${BASE_URL}/auth/send-reset-link`;
// User
export const USER_DETAILS = `${BASE_URL}/user/detail`;
export const USER_ROLE = `${BASE_URL}/user/roles`;
export const USER_PLATFORM = `${BASE_URL}/user/platform`;
export const SHOPIFY = `${BASE_URL}/platform/shopify-integration`;
export const UPLOAD_IMAGE = `${BASE_URL}/auth/upload-image`;
export const UPDATE_ONBOARDING_STATUS = `${BASE_URL}/user/onboarding/update`;

// Settings
export const RETAIL_PROFILE = `${BASE_URL}/user/retailer-profile`;

// Brand
export const PLATFORM = `${BASE_URL}/platform`;
export const BRAND_PROFILE = `${BASE_URL}/user/brand-profile`;
export const USER_BRAND_PROFILE = (id) =>
  `${BASE_URL}/user/brand-profile/${id}`;
export const BRAND_AS_CUSTOMER = `${BASE_URL}/payment/onboard-buyer`;
export const RETAILER_AS_CUSTOMER = `${BASE_URL}/sales-partner/create-retailer-as-customer`;
export const EXTERNAL_ACCOUNT = `${BASE_URL}/payment/customer/external-account`;
export const PAYMENT_CUSTOMER = `${BASE_URL}/payment/customer`;
export const BRAND_SHIPPING = `${BASE_URL}/user/brand-shipping`;
export const BRAND_SHIPPING_TIMES = `${BASE_URL}/platform/shipping-times`;
export const SYNC_PRODUCT_ALL = (id) =>
  `${BASE_URL}/shopify/sync-all-product?&user_id=${id}`;
export const SYNC_PRODUCT = `${BASE_URL}/shopify/sync-all-product`;
export const SYNC_SINGLE_PRODUCT = `${BASE_URL}/shopify/sync-product`;
export const PREFERENCES = `${BASE_URL}/brand/setting/preferences`;
export const NOTIFICATION = `${BASE_URL}/brand/setting/notifications`;
export const DISCONNECT_SHOPIFY = `${BASE_URL}/shopify/shopify-remove`;
export const RETAILER_REQUEST = `${BASE_URL}/brand/retailer-requests`;
export const UPDATE_RETAILER_REQUEST = `${BASE_URL}/retailer/request-connection/update`;
export const UPDATE_RETAILER_COMMISSION = `${BASE_URL}/retailer/update-commission`;
export const RETAILER_LIST = `${BASE_URL}/brand/retailers-list`;
export const RETAILER_BULK_LIST = `${BASE_URL}/brand/retailers`;
export const BRAND_PRODUCT_BULK_DELETE = `${BASE_URL}/brand/product/bulk-delete`;
export const UPDATE_BRAND_RETAILERS = `${BASE_URL}/brand/assign-product-to-retailer`;
export const UPDATE_BRAND_TAGS = `${BASE_URL}/brand/product/tags`;
export const UPDATE_BRAND_CATEGORIES = `${BASE_URL}/brand/product/categories`;
export const UPDATE_BRAND_VARIENT = `${BASE_URL}/brand/product/variants`;
export const PRODUCT_BULK_UPDATE = `${BASE_URL}/brand/product/bulk-update`;
export const ASSIGNED_UNASSIGNED_PRODUCT_BY_RETAILER = (retailerId, status) =>
  `${BASE_URL}/brand/retailer/${retailerId}/products/${status}`;
// Retailer
export const RETAILER_PROFILE = `${BASE_URL}/user/retailer-profile`;



export const RETAILER_DETAILS = (id) =>
  `${BASE_URL}/user/retailer-profile/${id}`;
export const RETAILER_BILLING = `${BASE_URL}/retailer/billing`;
export const RETAILER_BILLING_DELETE = `${BASE_URL}/retailer/billing/delete`;
export const RETAILER_BRANDS = `${BASE_URL}/retailer/brands`;
export const RETAILER_NOTIFICATION_ALERT = `${BASE_URL}/retailer/alert-notification`;
export const RETAILER_BRAND_VALUES = `${BASE_URL}/platform/values/brand`;
export const RETAILER_NEW_CONNECTION_REQUEST = `${BASE_URL}/retailer/request-connection`;
export const RETAILER_BRAND_PROFILE = (id) =>
  `${BASE_URL}/user/brand-profile/${id}`;
export const RETAILER_PRODUCTS = `${BASE_URL}/retailer/brand/product/list`;
export const RETAILER_PRODUCT_DETAILS = (ID) =>
  `${BASE_URL}/retailer/product/${ID}`;
export const BRAND_FILTERS = `${BASE_URL}/retailer/brand/dropdown`;
export const STORE_BRAND_FILTERS = `${BASE_URL}/retailer/store/brands`;
export const RETAILER_BRAND_TAGS_VALUE = (id) =>
  `${BASE_URL}/retailer/product-tags?brand=${id}`;
export const CONNECTED_RETAILER_CATEGORIES = `${BASE_URL}/brand/connected-retailers/all/retailer-categories`;
export const CONNECTED_RETAILER_STATES = `${BASE_URL}/brand/connected-retailers/all/states`;

// Product
export const PRODUCT_LIST = `${BASE_URL}/brand/product/list`;
export const DOWNLOAD_PRODUCT = `${BASE_URL}/brand/product/download`;
export const UPLOAD_PRODUCT = `${BASE_URL}/brand/product/upload`;
export const PRODUCT_TAGS = `${BASE_URL}/brand/product-tags`;
export const BRAND_PRODUCT_TAGS = `${BASE_URL}/brand/tags`;
export const BRAND_ADD_PRODUCT_TAG = `${BASE_URL}/brand/tag`;
export const UPDATE_PRODUCT_STATUS = `${BASE_URL}/brand/product/status`;
export const PRODUCT_CATEGORIES_PER_RETAILER = `${BASE_URL}/brand/product/category/all`;
export const PRODUCT_TAGS_PER_RETAILER = `${BASE_URL}/brand/product-tags`;
export const PRODUCT_UPLOAD_STATUS = `${BASE_URL}/brand/product/upload/status`;
export const PRODUCT_CATEGORIES = (id) =>
  `${BASE_URL}/brand/product/category/${id}`;
export const PRODUCT_DETAILS = (product_id) =>
  `${BASE_URL}/brand/product/${product_id}`;
export const EDIT_PRODUCT_DETAILS = (product_id) =>
  `${BASE_URL}/brand/product/${product_id}`;

// Common
export const CATEGORY = (role) => `${BASE_URL}/platform/category/${role}`;
export const VALUES = (role) => `${BASE_URL}/platform/values/${role}`;

//cart
export const ADD_TO_CART = `${BASE_URL}/cart/add`;
export const UPDATE_CART = `${BASE_URL}/cart/update`;
export const GET_CART = `${BASE_URL}/cart/list`;
export const REMOVE_ITEM_CART = `${BASE_URL}/cart/item/remove`;

// Order
export const PLACE_ORDER = `${BASE_URL}/order/place-order`;
export const LISTORDER = `${BASE_URL}/brand/orders`;
export const ORDERDETAILS = `${BASE_URL}/order/details`;

// General
export const COUNTRIES = `${BASE_URL}/general/countries`;
export const STATES = (id) => `${BASE_URL}/general/countries/${id}/states`;
export const RETAILER_CATEGORIES = `${BASE_URL}/platform/category/retailer`;
export const POS_PLATFORMS = `${BASE_URL}/platform`;

//brand retailer request
export const RETAILER_REQUEST_FOR_ACCESS = `${BASE_URL}/brand/retailer-requests`;
export const CONECTED_RETAILER = `${BASE_URL}/brand/connected-retailers`;

//brand sales repo
export const SALES_REP_ORDER_LIST = `${BASE_URL}/sales-reps/order`;
export const SALES_REP_LIST = `${BASE_URL}/sales-reps`;
export const SALES_REP_CREATE = `${BASE_URL}/sales-reps`;
export const SALES_REP_DELETE = `${BASE_URL}/sales-reps`;
export const SALES_REP_UNASSIGN_LIST = `${BASE_URL}/sales-reps/list`;
export const SALES_REP_ASSIGN_UNASSIGN = `${BASE_URL}/sales-reps/assign-remove`;

//shopify check update
export const SHOPIFY_CHECK_UPDATE = `${BASE_URL}/shopify/check-duplicate`;

// export const PRODUCT_TAGS = `${BASE_URL}/brand/product-tags`;
// export const PRODUCT_CATEGORIES = `${BASE_URL}/brand/product/category/0`;

export const PENDING_SUBUSERS = `${BASE_URL}/user/pending-sub-user`;
export const ALL_SUBUSERS = `${BASE_URL}/user/all-sub-user`;
export const ADD_SUBUSER = `${BASE_URL}/user/create`;
export const DELETE_SUBUSER = `${BASE_URL}/user/sub-user`;
export const UPDATE_SUBUSER = `${BASE_URL}/user/sub-user`;
export const RETAILER_ORDERS = `${BASE_URL}/retailer/orders`;
export const RETAILER_ORDERS_CHANNEL = `${BASE_URL}/channels/orders`;
export const BRAND_ORDERS = `${BASE_URL}/brand/orders`;
export const RETAILER_LIST2 = `${BASE_URL}/retailer/list`;
export const SUB_USER = `${BASE_URL}/general/sub-user/invites`;
// export const DOWNLOAD_RETAILER_ORDERS_REPORT = `${BASE_URL}/retailer/orders/download`;
export const DOWNLOAD_RETAILER_ORDERS_REPORT = `${BASE_URL}/brand/orders/download`;


// Plan
export const PLAN_LIST = `${BASE_URL}/recurly/plans`;
export const SUBSCRIPTION_UPDATE = `${BASE_URL}/recurly/subscription/update`;
export const BILLING_INFO = `${BASE_URL}/recurly/billing-info`;
export const BILLING_INFO_UPDATE = `${BASE_URL}/recurly/billing-info`;

export const BILLING_INVOICES = `${BASE_URL}/recurly/invoices`;
export const DOWNLOAD_INVOICE = `${BASE_URL}/recurly/invoice/download`;


export const RETAILER_ONBOARDING_UPDATE = `${BASE_URL}/retailer/onbording-update`;


export const VALIDATE_COUPON_CODE = `${BASE_URL}/recurly/coupon-info`;


export const TOGGLE_ALL_CHANNEL_STATUS = `${BASE_URL}/channels/toggle-all-channel-status`;

export const GET_ALL_BRANDS = `${BASE_URL}/admin/brands`;
export const GET_ALL_RETAILERS = `${BASE_URL}/admin/retailers`;
export const ADMIN_USER_LOGIN = `${BASE_URL}/admin/user/login`;

export const GET_ALL_USERS = `${BASE_URL}/admin/all-users`;
export const GET_ALL_SALES_PARTNERS = `${BASE_URL}/admin/sales-partners`;


export const RESET_USER_PASSWORD = `${BASE_URL}/auth/reset-user-password`;

export const RETAILER_CANCEL_SUBSCRIPTION = `${BASE_URL}/recurly/subscription/cancel`;
export const SYNC_WOOCOMMERCE_SINGLE_PRODUCT = `${BASE_URL}/woocommerce/sync-product`;
export const SYNC_MAGENTO_SINGLE_PRODUCT = `${BASE_URL}/magento/sync-product`;












 
export const RETAILER_PENDING_ORDERS = `${BASE_URL}/retailer/orders/shopify`;
 
export const GET_RETAILER_CHANNELS = `${BASE_URL}/channels`;

export const PULBLISH_RETAILER_LISTING = `${BASE_URL}/shopify/retailer/product/publish`;
export const RETAILER_PULBLISH_PRODUCT_LISTING = `${BASE_URL}/retailer/published-products`;
export const RETAILER_REMOVE_PUBLISHED_PRODUCT = `${BASE_URL}/shopify/retailer/product/remove-publish`;

export const RETAILER_SYNC_PUBLISHED_PRODUCT = `${BASE_URL}/shopify/retailer/product/update-published-variant`;


export const BRAND_FILTERS_FOR_PENDING_ORDERS = `${BASE_URL}/retailer/brand/dropdown?purpose=shopify-order`;

export const BRAND_FILTERS_FOR_SHOPIFY_CHANNEL = `${BASE_URL}/retailer/brand/dropdown?purpose=shopify-channels`;


export const BRAND_FILTERS_FOR_ALL_ORDERS = `${BASE_URL}/retailer/brand/dropdown?purpose=all-orders`;

export const RETAILER_ORDER_REQUIRED_ACTION = `${BASE_URL}/order/read-status`;

//store front
export const STORE_DETAILS = `${BASE_URL}/store`;
export const STORE_BRANDS = `${BASE_URL}/store/brands`;
export const STORE_PRODUCTS = `${BASE_URL}/store/products`;
export const STORE_PRODUCT_DETAILS = (id) => `${BASE_URL}/store/product/${id}`;
export const STORE_ADD_TO_CART = `${BASE_URL}/store/cart/add`;
export const STORE_CART_DATA = (deviceId, zipcode) => `${BASE_URL}/store/cart/list?device_id=${deviceId}${(zipcode || zipcode !== undefined) ? "&zipcode="+zipcode : ''}`;
export const STORE_PLACE_ORDER = `${BASE_URL}/store/orders`;
export const STORE_CART_REMOVE_PRODUCT = `${BASE_URL}/store/cart/item/remove`;
export const STORE_ORDER_DETAILS = (id) => `${BASE_URL}/store/orders/${id}`;
export const STORE_COUNTRY = `${BASE_URL}/store/general/countries`;
export const STORE_STATES = (id) => `${BASE_URL}/store/general/countries/${id}/states`;



export const RETAILER_ADD_STORE = `${BASE_URL}/retailer/store`;

export const VALIDATE_CUSTOM_DOMAIN = `${BASE_URL}/retailer/store/add-ssl`;

export const RETAILER_STORE_CHECK_SUBDOMAIN = `${BASE_URL}/retailer/store/check-subdomain`;
export const RETAILER_STORE_PRODUCT_SEQUENCE = `${BASE_URL}/retailer/store/product/sequence`;

export const RETAILER_GET_STORE = `${BASE_URL}/retailer/store`;

export const STORE_DATA_LIST = `${BASE_URL}/retailer/store/product/list`;
export const RETAILER_PRODUCT_PUBLISH_ACTION = `${BASE_URL}/retailer/store/product`;

export const RETAILER_PUBLISH_TO_ASSIGN_PRODUCT_ACTION = `${BASE_URL}/retailer/store/assign-products`;

export const RETAILER_PUBLISH_TO_UNASSIGN_PRODUCT_ACTION = `${BASE_URL}/retailer/store/unassign-products`;


export const RETAILER_STORE_DATA = `${BASE_URL}/retailer/store`;

export const STORE_CATEGORY_LIST = `${BASE_URL}/retailer/store/categories`;

export const RETAILER_PRODUCT_STORE_CATEGORY_UPDATE_ACTION = `${BASE_URL}/retailer/store/product/categories`;

export const RETAILER_DELETE_STORE = `${BASE_URL}/retailer/store`;


export const DOWNLOAD_BRAND_ORDERS_REPORT = `${BASE_URL}/brand/orders/download`;

export const UPLOAD_FILE = `${BASE_URL}/payment/upload-file`;


export const UPDATE_RETAILER_DISCOUNT = `${BASE_URL}/retailer/update-discount`;

export const USER_REFRESH = `${BASE_URL}/user/user-refresh`;

export const SUBSCRIPTION_INFO = `${BASE_URL}/recurly/subscription-info`;

export const WOOCOMMERCE_CHECK_UPDATE = `${BASE_URL}/woocommerce/check-duplicate`;

export const MAGENTO_CHECK = `${BASE_URL}/magento/validate-store`;

export const WOOCOMMERCE_SYNC_PRODUCT = `${BASE_URL}/woocommerce/sync-all-product`;

export const MAGENTO_SYNC_PRODUCT = `${BASE_URL}/magento/sync-all-product`;

export const DISCONNECT_WOOCOMMERCE = `${BASE_URL}/woocommerce/woocommerce-remove`;

export const DISCONNECT_MAGENTO = `${BASE_URL}/magento/magento-remove`;

export const RECONNECT_MAGENTO = `${BASE_URL}/magento/magento-reconnect`;

export const RECONNECT_WOOCOMMERCE = `${BASE_URL}/woocommerce/woocommerce-reconnect`;

export const WOOCOMMERCE_SYNC_MULTI_PRODUCTS = `${BASE_URL}/woocommerce/sync-multi-product`;

export const SHOPIFY_SYNC_MULTI_PRODUCTS = `${BASE_URL}/shopify/sync-multi-product`;

export const UPDATE_MULTIPLE_RETAILER_COMMISSION = `${BASE_URL}/retailer/update-multiple-retailer-commission`;

export const UPDATE_MULTIPLE_RETAILER_DISCOUNT = `${BASE_URL}/retailer/update-multiple-retailer-discount`;

export const DELETE_MULTIPLE_RETAILER_DISCOUNT = `${BASE_URL}/retailer/delete-multiple-retailer-discount`;

export const MAGENTO_CHECK_UPDATE = `${BASE_URL}/magento/check-duplicate`;

export const BUYER_PAYMENT_INSTRUMENT = `${BASE_URL}/payment/buyer-payment-instrument`;